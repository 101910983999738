/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { baseInput } from '../../elements/input/styles';
import { routes } from '../../shared/constants';

const StyledContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding: 0px 40px;

  @media (max-width: ${props => props.theme.components.phoneWidthMax}) {
    padding: 0px 30px;
  } 
`;

const FieldContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${baseInput}
`;

const ForgotPasswordContainer = styled.div`
  text-align: right;
`;

const ForgotPassword = styled.a`
  display: inline-block;
  margin-bottom: 30px;
  font-size: 14px;
`;

const ErrorContainer = styled.div`
  color: ${props => props.theme.colours.red};
  margin: 30px 0px;
`;

const LoginButton = styled(Button)({
  // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  background: '#017A68',
  border: 0,
  borderRadius: 3,
  color: 'white',
  height: 48,
  padding: '0 30px',
  transition: 'background 0.3s ease', // smooth transition effect
  '&:hover': {
    background: 'black',
  },
});

export default function LoginForm({
  data
}) {
  const {
    hashId,
    loginHeading,
    submitButton,
    forgotPasswordButton,
    forgotPasswordUrl
  } = data;

  const [errorType, setErrorType] = useState(false);
  // INT-412 default loginRoute to CashDoctors if you need to bypass fgf login functionality
  // const [loginRoute, setLoginRoute] = useState('CashDoctors');
  const [loginRoute, setLoginRoute] = useState('');
  const [loading, setLoading] = useState(false);

  const emailRegex = `[a-zA-Z0-9!#$%&-_'*+\/=?^_{|}~.]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$`;
  const passwordRegex = `.{4,}`;

  const [passedUsername, setPassedUsername] = useState('');
  
  useEffect(() => {
      const urlSearchString = window.location.search;
      const params = new URLSearchParams(urlSearchString);
      const passedUser = params.get('nimbleuser') || '';
      setPassedUsername(passedUser);
  }, []);

  const checkLoginRoute = async (formData) => {
    let route;
    try {
      setLoading(true);
      route = await fetch(`${routes.global.urls.domains.root}mobile/routeemail?__amp_source_origin=https%3A%2F%2F${window.document.domain}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'amp-same-origin': 'true'
        },
        body: formData
      })
        .then(response => response.json())
        .then((response) => {
          if (response.status === 'error') {
            setErrorType('status');
            return {route: 'error'};
          } else {
            setErrorType(false);
            const { Route = 'error' } = response;
            return {route: Route};
          }
        })
    } catch (error) {
      setErrorType('status');
      return {route: 'error'};
    } finally {
      setLoading(false);
    }
   
    return route;

  }

  const loginCashDoctor = async (formData) => {
    try {
      setLoading(true);
      await fetch(`${routes.global.urls.domains.root}mobile/submitlogin/?__amp_source_origin=https%3A%2F%2F${window.document.domain}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'amp-same-origin': 'true'
        },
        body: formData
      })
        .then(response => response.json())
        .then((response) => {
          if (response.status === 'error') {
            if (response.lockedout) {
              setErrorType('lockedout');
            } else if (response.incorrect) {
              setErrorType('incorrect');
            } else if (response.unknown) {
              setErrorType('unknown');
            } else {
              setErrorType('status');
            }
          } else {
            setErrorType(false);
            setLoading(false);
            window.location.href = `${routes.global.urls.domains.root}account`;
          }
        })
    } catch (error) {
      setErrorType('status');
    } finally {
      setLoading(false);
    }

    return;
    
  }

  const loginNimbleFsa = (username) => {
    window.location.href = `${routes.global.urls.fsaNimble.signin}?username=${username}`;
    return;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(document.getElementById(`loginform-${hashId}`));
    const username = formData.get('username');
    
    if (loginRoute === 'CashDoctors') {
      return loginCashDoctor(formData);
    } else {
     
      const {route = 'error'} = await checkLoginRoute(formData);
      
      setLoginRoute(route);

      if (route !== 'CashDoctors' && route !== 'error') {
        loginNimbleFsa(username);
      }
    }
    
    return;
  };

  const isCashDoctorsLogin = () => {
    return  (loginRoute && loginRoute === 'CashDoctors');
  }

  const handleUsernameChange = (event) => {
    setPassedUsername(event.target.value);
  };

  return (
    <div id={hashId}>
      <StyledContainer>
        <h1>{loginHeading}</h1>
        <form id={`loginform-${hashId}`} method="post" target="_top" onSubmit={handleSubmit}>
          <FieldContainer>
            <StyledLabel htmlFor="username">Email*</StyledLabel>
            <StyledInput id="username" name="username" type="email" value={passedUsername} onChange={handleUsernameChange} autoComplete="off" pattern={emailRegex} required />
          </FieldContainer>
          {isCashDoctorsLogin() && (
          <FieldContainer>
            <StyledLabel htmlFor="password">Password*</StyledLabel>
            <StyledInput id="password" name="password" type="password" autoComplete="off" pattern={passwordRegex} required />
          </FieldContainer>
          )}

          {forgotPasswordUrl && forgotPasswordUrl && isCashDoctorsLogin() && (
            <ForgotPasswordContainer>
              <ForgotPassword href={forgotPasswordUrl}>{forgotPasswordButton}</ForgotPassword>
            </ForgotPasswordContainer>
          )}

          {/* Button */}
          
          <LoginButton
            type='submit'
            variant='contained'
            // className="button btn-green"
            disabled={loading}
          >
            <Typography style={{ textTransform: 'none', font: '16px SharpGroteskBook, sans-serif' }} >
              {loading ? <CircularProgress size={24} /> : isCashDoctorsLogin() ? submitButton : 'Continue'}
            </Typography>
          </LoginButton>
         
          {/* Errors */}
          {errorType
            && (
              <ErrorContainer>

                {errorType === 'status'
                  && (
                    <div>
                      <b>Oops, there was a problem logging in!</b>
                      <br />
                      <span>
                        Looks like our system is having troubles, have another go or come
                        back later.
                      </span>
                      <br />
                    </div>
                  )}

                {errorType === 'unknown'
                  && (
                    <div>
                      <b>Oops! Something went wrong.</b>
                      <br />
                      <span>We have sent our IT guys a report. Sorry about that.</span>
                      <br />
                    </div>
                  )}

                {errorType === 'incorrect'
                  && (
                    <div>
                      <b>Oops, wrong email or password!</b>
                      <br />
                      Please try again or
                      <a href="/login/forgot-password/">click here to recover.</a>
                      <br />
                    </div>
                  )}

                {errorType === 'lockedout'
                  && (
                    <div>
                      <b>You have been locked out of your account.</b>
                      <br />
                      <span>Please contact our customer service team on 133 156.</span>
                      <br />
                    </div>
                  )}
              </ErrorContainer>
            )}
        </form>
      </StyledContainer>
    </div>
  );
}

LoginForm.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    loginHeading: PropTypes.string,
    submitButton: PropTypes.string,
    forgotPasswordButton: PropTypes.string,
    forgotPasswordUrl: PropTypes.string
  })
};
